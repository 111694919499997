<template>
  <div id="nav" class="glassFilter">
    <a href="/">📹こんざつらいぶ！<span class="subTxt"> / 工学部宗ゼミ</span></a>
  </div>
  
  <div id="main">
    <router-view class="routerView"></router-view>
  </div>
  
  <div id="footer" class="glassFilter">
    <router-link to="/" id="home" class="routerLink" title="ホームに戻ります。">ホーム</router-link>
    <router-link to="/live?cam=1" id="livecam1" class="routerLink" title="屋外飲食店の様子を配信しています。カメラ2とは、別の角度で配信しています。">カメラ1</router-link>
    <router-link to="/live?cam=2" id="livecam2" class="routerLink" title="屋外飲食店の様子を配信しています。カメラ1とは、別の角度で配信しています。">カメラ2</router-link>
    <router-link to="/live?cam=3" id="livecam2" class="routerLink" title="屋外飲食店付近の飲食スペースを配信しています。">カメラ3</router-link>
  </div>
  
  <a id="kawaiiMode" class="rightSide bottomButton" href="javascript:void(0)" @click="toggleKawaiiMode()" :title="`${ modeTitle }`">{{ modeText }}</a>
  <a id="surveyButton" class="leftSide bottomButton" href="https://docs.google.com/forms/d/e/1FAIpQLScWsQ1RC5l3G8Q5YI_gfnWxaozWIJx4gGHtzNnqyY7tEMZdwQ/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" title="アンケートにご協力ください。新しいタブで開きます。">　アンケート　</a>
</template>

<style>

</style>

<script>
  let kawaiiMode = 'かわいいモード';
  let normalMode = 'ふつうモード';
  let kawaiiModeTitle = 'このサイトをかわいくするモードです。';
  let normalModeTitle = 'このサイトをふつうにするモードです。(戻しちゃうの？)';

  export default {
    name: 'App',
    data() {
      return {
        modeTitle: kawaiiModeTitle,
        modeText: kawaiiMode
      };
    },
    mounted() {
      this.loadKawaiiMode();
      twemoji.parse(document.body);
    },
    watch: {
      $route() {
        this.loadKawaiiMode();
        twemoji.parse(document.body);
        
        let path = this.$route.fullPath;
        let linkColor = "#00bb85";
        let links = document.querySelectorAll('.routerLink');

        links.forEach(link => {
          let href = link.getAttribute('href');
          if (href === '/' && path === '/') {
            link.style.color = linkColor;
          }
          else if (href === '/live?cam=1' && path === '/live?cam=1') {
            link.style.color = linkColor;
          }
          else if (href === '/live?cam=2' && path === '/live?cam=2') {
            link.style.color = linkColor;
          }
          else if (href === '/live?cam=3' && path === '/live?cam=3') {
            link.style.color = linkColor;
          }
          else {
            link.style.color = '';
          }
        });
        this.loadKawaiiMode();
      }
    },
    methods: {
      toggleKawaiiMode() {
        if (this.isKawaiiMode()) {
          document.body.classList.remove('kawaii');
          this.modeTitle = kawaiiModeTitle;
          this.modeText = kawaiiMode;
          localStorage.setItem('kawaiiMode', 'false');
        } else {
          document.body.classList.add('kawaii');
          this.modeTitle = normalModeTitle;
          this.modeText = normalMode;
          localStorage.setItem('kawaiiMode', 'true');
        }
      },
      loadKawaiiMode() {
        if (this.isKawaiiMode()) {
          document.body.classList.add('kawaii');
          this.modeTitle = normalModeTitle;
          this.modeText = normalMode;
        } else {
          document.body.classList.remove('kawaii');
          this.modeTitle = kawaiiModeTitle;
          this.modeText = kawaiiMode;
        }
      },
      isKawaiiMode() {
        return localStorage.getItem('kawaiiMode') === 'true';
      }
    }
  };
</script>