<template>
  <h1>
    📹 Live <br>
    <span class="subTxt">{{ camPlace }} <br class="h1MinBr">カメラ{{ camNo }}</span>
  </h1>

  <div class="liveCam">
    <iframe 
    width="100%" 
    height="auto" 
    :src="`https://www.youtube.com/embed/live_stream?channel=${ youtubeUrl }`"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    alt="YouTube Live"
    ></iframe>
  </div>

  <p>
    {{ camInfo }}<br>
    ※カメラの不調により、配信が中断される場合があります。<br>
    <br>
  </p>
</template>

<style>
  @media screen and (min-width: 455px) {
    .h1MinBr{
      display: none;
    }
  }
  .liveCam {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    
    & iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
  }
</style>

<script>
  //UserID
  const live1 = 'UC8j2nQifUValaYNTZH4LrIg';
  const live2 = 'UCirYfi_bp4GI3rkNBq9EFyw';
  const live3 = 'UC_xRbEN7B-r8rMyhQgv9wVw';

  const foodStand = "屋外飲食店"
  const restPlace = "屋外飲食スペース";
  const camInfo = "屋外飲食店の様子を配信しています。";
  const camInfo3 = "屋外飲食店付近の飲食スペースを配信しています。";
  
  export default {
    name: 'livePage',
    data() {
      let newCam = this.$route.query.cam;

      return {
        camPlace: newCam=== '3' ? restPlace : foodStand,
        camNo: newCam,
        camInfo: newCam=== '3' ? camInfo3 : camInfo,
        youtubeUrl: newCam=== '1' ? live1 : newCam === '2' ? live2 : newCam === '3' ? live3 : newCam === '',
      };
    },
    mounted() {
      twemoji.parse(document.body);
    },
    watch: {
      '$route.query.cam'(newCam) {
        this.camPlace = newCam === '3' ? restPlace : foodStand;
        this.camNo = newCam;
        this.camInfo = newCam === '3' ? camInfo3 : camInfo;
        this.youtubeUrl = newCam === '1' ? live1 : newCam === '2' ? live2 : newCam === '3' ? live3 : newCam === '';
      }      
    }
  };
</script>