import { createRouter, createWebHistory } from "vue-router"
import home from "./components/index.vue"
import live from "./components/live.vue"
import notFound from "./components/404.vue"

const validateCamParam = (to, from, next) => {
  const validCams = [1, 2, 3];
  const cam = parseInt(to.query.cam, 10);
  if (validCams.includes(cam)) {
    next();
  } else {
    next({ name: 'notFound' });
  }
};

const routes = [
  { path: "/", component: home, name: "homePage", meta: { title: "こんざつらいぶ！ / 麗澤大学" }},
  { path: "/live", component: live, name: "livePage", mata: { title: "混雑状況配信 | こんざつらいぶ！ / 麗澤大学" }, beforeEnter: validateCamParam},  
  { path: "/:pathMatch(.*)*", component: notFound, name: "notFound", meta: { title: "404 Not Found | こんざつらいぶ！ / 麗澤大学" }},
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;