<template>
  <h1>
    🏠 Home <br>
    <span class="subTxt">麗陵祭 混雑状況配信</span>
  </h1>
  <div>
    工学部宗ゼミは、麗陵祭の混雑状況を配信しています。<br>
    以下のリンクから、混雑状況を確認してみましょう！<br>
    <br>
    <div id="camList" class="linkList">
      <span class="listTitle">カメラ設置場所</span><br>
      <p>※カメラの不調により、配信が中断される場合があります。</p>

      <ul>
        <li>
          🍽️ 屋外飲食店: 2台
          <ul>
            <li><router-link to="/live?cam=1" class="routerLink" title="屋外飲食店の様子を配信しています。カメラ2とは、別の角度で配信しています。">混雑を確認する！<br class="minBr">(カメラ1)</router-link></li>
            <li><router-link to="/live?cam=2" class="routerLink" title="屋外飲食店の様子を配信しています。カメラ1とは、別の角度で配信しています。">混雑を確認する！<br class="minBr">(カメラ2)</router-link></li>
          </ul>
        </li>
        <br>
        <li>
          🛋️ 屋外飲食スペース: 1台
          <ul>
            <li><router-link to="/live?cam=3" class="routerLink" title="屋外飲食店付近の飲食スペースを配信しています。">混雑を確認する！<br class="minBr">(カメラ3)</router-link></li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          柴崎ゼミ・新井ゼミ
          <ul>
            <li><a href="https://reitaku-rcm.backdrop.jp/index_okugai.html" title="" target="_blank" rel="noopener noreferrer">麗澤混雑マップ</a></li>
          </ul>
        </li>
      </ul>
    </div>

    <div id="extList" class="linkList">
      <span class="listTitle">外部リンク</span><br>
      <p>※以下のサイトは、当サイトとは別の運営となっております。<br class="extMinBr">お問い合わせは、各サイトへお願いいたします。</p>
      <ul>
        <li><span class="listTitle">🏫 麗澤大学 関連サイト</span></li>
        <ul>
          <li><a href="https://sites.google.com/view/reitaku-gakuensai/" title="工学部ゼミの特設サイトです。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">💻 工学部ゼミ 特設サイト</a></li>
          <br>
          <li><a href="https://sites.google.com/reitaku.jp/reiryosai2023/" title="麗陵祭の公式ウェブサイトです。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">✨ 麗陵祭 公式Webサイト</a></li>
          <li><a href="https://www.reitaku-u.ac.jp/" title="麗澤大学の公式ウェブサイトです。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">🏫 麗澤大学 公式Webサイト</a></li>
          <li><a href="https://www.reitaku-u.ac.jp/faculty/engineering/" title="麗澤大学 工学部の公式ウェブサイトです。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">🤖 麗澤大学 工学部 公式Webサイト</a></li>
        </ul>
        <br>
        <li><span class="listTitle">🚌 東武バス 接近情報</span></li>
        <ul>
          <li><a href="https://www.tobu-bus.com/cmn/search_section.php?s=61159&scd=61159&e=61155" title="向原バス停 南柏駅行きバスの接近情報です。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">向原バス停 南柏駅行きバス</a></li>
          <li><a href="https://www.tobu-bus.com/cmn/search_section.php?s=61160&scd=61160&e=61155" title="麗澤大学前バス停 南柏駅行きバスの接近情報です。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">麗澤大学前バス停 南柏駅行きバス</a></li>
        </ul>
      </ul>
    </div>
    <a id="contactForm" href="https://docs.google.com/forms/d/e/1FAIpQLSeuohZJgcGteSc6OlpzSJvf56r3UaTO1P4Ogt-h4kHyhEHXSw/viewform" title="このサイトへのお問い合わせフォームです。新しいタブで開きます。" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
  </div>
</template>

<style>

</style>

<script>
export default {
    name: 'homePage',
    data() {
      return {
      };
    },
    mounted() {
      twemoji.parse(document.body);
    },
    watch: {
      $route() {
        twemoji.parse(document.body);
      }
    }
  };
</script>